.mapboxgl-ctrl-bottom-right {
  display: none !important;
}

#marker {
  background-image: url('https://docs.mapbox.com/mapbox-gl-js/assets/washington-monument.jpg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.marker {
  width: 0;
  height: 0;
}

.marker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #fff;
  background: #4d2d73;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg);
}

.temporary-marker span {
  background: #d3d3d3;
}

[aria-label='nextInforWindow'] {
  padding: 0px !important;
  background-color: #6FA8F8 !important;
  outline: none !important;
}

[aria-label='nextInforWindow'] .gm-style-iw-d {
  overflow: hidden !important;
}

[aria-label='nextInforWindow'] .gm-style-iw-d>div {
  height: 30px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[aria-label='nextInforWindow'] button,
[aria-label='nextInforWindow']+.gm-style-iw-tc {
  display: none !important;
}

[aria-label='nextInforWindow']+div::after,
[aria-label='nextInforWindow'] .gm-style-iw-d,
[aria-label='nextInforWindow'] button,
[aria-label='nextInforWindow'] .gm-style-iw-d div,
[aria-label='nextInforWindow'] .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background-color: #6FA8F8 !important;
}

[aria-label='nextInforWindowComplete'] {
  padding: 0px !important;
  background-color: #c9c9c9 !important;
  outline: none !important;
}

[aria-label='nextInforWindowComplete'] .gm-style-iw-d {
  overflow: hidden !important;
}

[aria-label='nextInforWindowComplete'] .gm-style-iw-d>div {
  height: 30px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

[aria-label='nextInforWindowComplete'] button,
[aria-label='nextInforWindowComplete']+.gm-style-iw-tc {
  display: none !important;
}

[aria-label='nextInforWindowComplete']+div::after,
[aria-label='nextInforWindowComplete'] .gm-style-iw-d,
[aria-label='nextInforWindowComplete'] button,
[aria-label='nextInforWindowComplete'] .gm-style-iw-d div,
[aria-label='nextInforWindowComplete'] .gm-style-iw-d::-webkit-scrollbar-track-piece {
  background-color: #c9c9c9 !important;
}

[aria-label='nextInforWindowDetail'] {
  outline: none !important;
}

[aria-label='nextInforWindowDetail'] button,
[aria-label='nextInforWindowDetail']+.gm-style-iw-tc {
  display: none !important;
}